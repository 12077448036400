body {
	color: $color-white;
	font-family: $font-primary;
	font-size: 16px;
}

body * {
	box-sizing: border-box;
	@include no-select();
}

.app {
	padding: 0.5em;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	background-color: $color-black;
}

.logo {
	position: fixed;
	width: 8em;
	pointer-events: none;
}

.site-title {
	margin-top: 1em;
	margin-bottom: 1em;
	color: #c7c7c7;
	font-size: 2em;
	line-height: 1em;
	font-weight: 400;
	text-transform: none;
}

.alert {
	margin: 0 auto;
	width: 150px;
	border: $color-3 1px solid;
	color: $color-3;
	text-align: center;
}

.map {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;

	&--container {
		height: 100%;
		width: 100%;
	}

	&--alert {
		@extend .vertical-align;
		@extend .alert;
		width: 300px;
		position: absolute;
		margin-left: -150px;
		left: 50%;
	}
}

.box {
	position: relative;
	// position: fixed;
	// top: 5em;
	// left: 0;
	// height: 5em;
	// height: auto;
	// width: 5em;
	z-index: 2;
	background-color: $color-gray;
	border-left: 1px solid $color-3;
	border-right: 1px solid $color-3;
    @include box-shadow(0.75em, 0.75em, 0px, rgba($color-black, 0.75));

    &.minimized {
    	width: 5em!important;
    	overflow: hidden;
    	.box-inner--content {
    		display: none;
    	}
    }

    &-pinned {
    	z-index: 2147483647 ! important;
    }

    &.box-draggable {

    	.box-inner h1 {
			cursor: move;
		}
	}

	&-inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0 0.5em;
		border-bottom: 1px solid $color-5;
		border-top: 1px solid $color-5;

		&--content {
			overflow-y: auto;
		    position: absolute;
		    top: 3em;
		    bottom: 0.5em;
		    left: 0;
		    right: -0.5em;
		    padding-right: 0.5em;
		}
	}

	&-size {
		position: fixed;
		top: 5em;

		&-rect {
			@extend .box-size;
			height: 21em;
			width: 13em;
			z-index: 200;
		}

		&-button {
			@extend .box-size;
			height: 7.6em;
			width: 5em;
		}

		&-small {
			@extend .box-size;
			height: 13em;
			width: 13em;
		}
		&--free-height {
			@extend .box-size;
			position: relative;
			top: 0;
			height: auto;
			width: 300px;

			& .box-inner {
				position: relative;
			}
		}
	}

	h1 {
		margin: 0;
		padding: 0.75em 0;
		border-bottom: 1px solid $color-5;
		color: $color-4;
		font-size: 1em;
		line-height: 1em;
		text-transform: uppercase;
	}

	&--toggle-button {

		&::before {
			position: absolute;
			top: 0.125em;
			right: 0;
			height: 1em;
			width: 1em;
			color: $color-6;
			content: '—';
			cursor: pointer;
			font-size: 2em;
			line-height: 1em;
			text-align: center;
		}
		&.minimized::before{
			content: '+';
		}
	}
}

#Camera {
	top: 13em;
	left: 5em;
}

#CameraAdd {
	top: 30%;
}

#Profile {
	width: 13em;
	height: 9em;
	top: 2em;
	left: 30%;
}
.content {
	&--square {
		position: relative;
		float: left;
		width: 4em;
		height: 4em;
		margin-right: 0.5em;
		border: 1px solid $color-4;

		&--full {
			@extend .content--square;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: auto;
			height: auto;
		}
	}

	&--title {
		height: 1.5em;
		line-height: 1.5em;
		overflow: hidden;
	}

	&--subtitle {
		color: $color-3;
		line-height: 1.5em;
	}
}

.background {
	background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

	&--action {

		&-hack {
			@extend .background;
			background-image: url('/assets/img/action-hack.svg');
		}
	}

	&--add {
		@extend .background;
		background-image: url('/assets/img/addCamera.svg');
	}

	&--logo {

		&-dio {
			@extend .background;
			background-image: url('/assets/img/team-dio.svg');
		}

		&-lens {
			@extend .background;
			background-image: url('/assets/img/team-lens.svg');
		}

		&-blind {
			@extend .background;
			background-image: url('/assets/img/team-blind.svg');
		}
	}

	&-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}

.items {
	clear: both;
	margin: 0;
	padding: 0;

	li {
		padding: 0.25em 0;
		border-bottom: 1px solid $color-1;
		line-height: 1em;
		list-style: none;
		overflow: hidden;
		white-space: nowrap;

		&:first-child {
			border-top: 1px solid $color-1;
		}

		.countdown {
			text-align: right;
		}
	}

	&--icon {
		width: 2em;
		height: 2em;
		float: left;
		margin-right: 0.25em;
	}
}

.countdown {
	color: $color-3;
}

.cameraInfo-image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}