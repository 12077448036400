label {
	display: block;
	margin: 0.5em 0;
	padding-bottom: 0.5em;
	border-color: #c465c3;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: #4a84d8;
	font-weight: 700;
	text-align: left;
	text-transform: lowercase;
}

input[type=text],
input[type=password] {
	@include select();
	@extend .input-single-text;
}

select {
	@extend .input-single-text;
	border-radius: 0;
	appearance: none;
    -webkit-appearance: none;
    position: relative;
	background-position: right 0px top 50%;
	background-repeat: no-repeat;
	background-image: url('/assets/img/dropdownArrow.svg');
}

input[type=file] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

	& + label {
		.button {
			float: left;
			margin-right: 0.5em;
		}
		.label {
			display: block;
			height: 2.5em;
			max-width: 100%;
			overflow: hidden;
		}
	}

	& + label * {
		// pointer-events: none;
	}

	.inputfile:focus + label .button {
		@extend .button:focus;
	}
}



.input-single-text {
	width: 100%;
	margin-bottom: 1em;
	padding: 2px 0em;
	border-style: none;
	border-bottom: 2px solid $color-6;
	background-color: transparent;
	color: $color-6;
	line-height: 1.4;

	&:focus {
		background-color: $color-black;
		outline: 0;
	}

	&:disabled {
		border-color: $color-white;
		color: $color-white;
	}
}

.vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

input[type=submit],
button {
	@extend .button;
}

fieldset:disabled .button {
	@extend .button:disabled;
}

.button {
	display: inline-block;
	min-width: 3em;
	margin-left: 0.5em;
	padding: 0.5em 1em;
	border: 2px solid $color-6;
	background-color: $color-gray;
	color: $color-6;
	cursor: pointer;
	font-size: 1em;
	line-height: 1em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	&:first-of-type {
		margin-left: 0;
	}

	&:hover,
	&:active,
	&:focus {
		background-color: $color-6;
		color: $color-gray;
	}

	&:focus {
		outline: 0;
	}

	&:disabled {
		&:hover,
		&:active,
		&:focus {
			background-color: transparent;
		}		
		color: $color-white;
		border-color: $color-white;
	}

}

.button-container {
	text-align: center;
	margin: 0.5em 0;

	&--right {
		text-align: right;
	}
}