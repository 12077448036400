@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin select() {
  -webkit-touch-callout: auto; /* iOS Safari */
    -webkit-user-select: auto; /* Safari */
     -khtml-user-select: auto; /* Konqueror HTML */
       -moz-user-select: auto; /* Firefox */
        -ms-user-select: auto; /* Internet Explorer/Edge */
            user-select: auto; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color;
        -moz-box-shadow:inset $top $left $blur $color;
        box-shadow:inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}


// Breakpoints
@mixin breakpoint($point) {
    @if $point == xs {
        @media (max-width: $screen-xs-max) { @content; }
    }
    @else if $point == sm {
        @media (min-width: $screen-sm-min) { @content; }
    }
    @else if $point == md {
        @media (min-width: $screen-md-min) { @content; }
    }
    @else if $point == lg {
        @media (min-width: $screen-lg-min) { @content; }
    }
}