.login {
	&--container {
		width: 300px;
		max-width: 100%;
		margin: 0 auto;
		text-align: center;

		.logo {
			position: relative;
			width: 100%;
			height: auto;
		}
	}
}